<!--
File: InvTypeEventDropdown.vue
Description: show the dropdown combo with the Inventory Type Event list .
-->
<template>
  <md-field class="select-dropdown">
    <label for="inventory_type_event_id">{{ label }}</label>
    <md-select required v-model="inventory_type_event_id" name="inventory_type_event_id" id="inventory_type_event_id"
       @md-selected='onChange'>
      <md-option v-for="(ev, ind) in invtype_events" :key="ind" :value="ev.inventory_type_event_id">
        {{ ev.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'inventory-type-event-dropdown',

    data() {
      return {
        inventory_type_event_id: null,
      }
    },

    props: {
      label: { default: null, type: String },
      initial_value: { default: null, type: Number }
    },

    mounted() {
      this.$store.dispatch('GET_INVTYPE_EVENT_DROPDOWN').then(() => {
        this.inventory_type_event_id = this.initial_value
      })
    },

    methods: {
      onChange() {
        this.$emit('input', this.inventory_type_event_id)
      },
    },

    computed: {
      invtype_events() {
        return this.$store.state.Dropdowns.invtype_events;
      }
    }
  }
</script>
<style lang="scss" scoped>
.select-dropdown {
  width: 100%;
  display: inline-block;
  overflow: visible;
}
</style>
